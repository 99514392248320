window.$ = window.jQuery = require('jquery');
const Klaro = require('klaro');
var bootstrap = require('bootstrap');

import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';

const input = document.querySelector('#mobile-number');
if (input != null) {
	window.iti = intlTelInput(input, {
		initialCountry: 'de',
		autoPlaceholder: false,
		onlyCountries: ['de', 'ch', 'at'],
		utilsScript: '/js/utils.js'
	});
}

const config = {
	elementID: 'klaro',
	privacyPolicy: '/datenschutz',
	groupByPurpose: true,
	storageMethod: 'cookie',
	cookieName: 'klaro',
	cookieExpiresAfterDays: 365,
	default: false,
	mustConsent: false,
	acceptAll: true,
	hideDeclineAll: false,
	hideLearnMore: false,
	lang: 'de',
	translations: {
		de: {
			googleAnalytics: {
				description: 'Sammeln von Besucherstatistiken',
			},
			recaptcha: {
				description: 'Aussortieren von Spammern',
			},
			purechat: {
				description: 'Kontakt via Chat',
			},
			disqus: {
				description: 'Kommentare',
			},
			shariff: {
				description: 'Teilen von Seiten auf Sozialen Medien',
			},
			purposes: {
				analytics: 'Besucher-Statistiken',
				security: 'Sicherheit',
				livechat: 'Live Chat',
				advertising: 'Anzeigen von Werbung',
				styling: 'Styling',
				sharing: 'Teilen von Inhalten',
			},
		},
		en: {
			googleAnalytics: {
				description: 'User Statistics',
			},
			recaptcha: {
				description: 'Prevent Spam',
			},
			purechat: {
				description: 'Contact via Purechat',
			},
			disqus: {
				description: 'Comments via Disqus',
			},
			shariff: {
				description: 'Sharing of Pages to Social Media',
			},
			purposes: {
				analytics: 'Analytics',
				security: 'Security',
				livechat: 'Livechat',
				advertising: 'Advertising',
				styling: 'Styling',
				sharing: 'Sharing of Content',
			},
		}
	},
	services: [{
			name: 'googleAnalytics',
			title: 'Google Analytics',
			purposes: ['analytics'],
		},
		{
			name: 'recaptcha',
			title: 'Recaptcha',
			purposes: ['security'],
		},
		{
			name: 'purechat',
			title: 'PureChat',
			purposes: ['livechat']
		},
		{
			name: 'disqus',
			title: 'Disqus',
			purposes: ['livechat']
		},
		{
			name: 'shariff',
			title: 'Share Buttons',
			purposes: ['sharing']
		}
	]
};

Klaro.setup(config);
window.klaro = Klaro;
window.klaroConfig = config;

var loadedCallback = function () {
	var element = document.getElementById('contactform');
	if (element != null) {
		element.classList.remove('disabledwrapper');
		document.getElementById('info').style.display = 'none';
	}
};

window.loadedCallback = loadedCallback;
